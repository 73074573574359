import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: {
      name: "Merchant"
    }
  },
  {
    path: "/merchant",
    name: "Merchant",
    component: () =>
      import("../views/introduce/merchant")
  },
  {
    path: "/apply",
    name: "Apply",
    component: () => import("../views/apply"),
  },
  {
    path: "/apply/:type",
    name: "ApplyAdd",
    component: () => import("../views/apply/apply")
  },
  {
    path: "/prepare",
    name: "Prepare",
    component: () => import("../views/introduce/prepare")
  },
  {
    path: "/one-yard",
    name: "oneYard",
    component: () => import("../views/introduce/oneYard")
  },
  {
    path: "/done",
    name: "Done",
    component: () => import("../views/introduce/review-tip")
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
