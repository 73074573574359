import axios from "axios";
const BASE_URL = 'http://pay.bjzwxt.com/index.php/api';
export default {
  /**
   * 银行卡识别
   * @param {Object} params
   * @param {File} params.img_url
   */
  bankInfo(params) {
    let formData = new FormData();
    for(let i in params) {
      formData.append(i, params[i]);
    }
    return axios.post(`${BASE_URL}/common/ocrbank`, formData);
  },
  /**
   * 身份证识别
   * @param {Object} params
   * @param {File} params.file
   */
  idcardInfo(params) {
    let formData = new FormData();
    for(let i in params) {
      formData.append(i, params[i]);
    }
    return axios.post(`${BASE_URL}/common/ocridcard`, formData);
  },
  /**
   * 营业执照识别
   * @param {Object} params
   * @param {File} params.file
   */
  licenseInfo(params) {
    let formData = new FormData();
    for(let i in params) {
      formData.append(i, params[i]);
    }
    return axios.post(`${BASE_URL}/common/bizlicense`, formData);
  },
  /**
   * 获取短信验证码
   * @param {Object} params
   * @param {String} params.mobile 手机号
   */
  sendSms(params) {
    let formData = new FormData();
    for(let i in params) {
      formData.append(i, params[i]);
    }
    return axios.post(`${BASE_URL}/common/sendsms`, formData);
  },
  saveApply(params) {
    let formData = new FormData();
    for(let i in params) {
      formData.append(i, params[i]);
    }
    return axios.post("http://106.15.33.231/index.php/api/applyment4sub/add", formData);
  }
} 