import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import IconFont from '@/components/icon-font'
import 'vant/lib/index.less';
import "@/styles/index.less";
import api from "@/api";

let icon = document.createElement("script");
icon.src = "//at.alicdn.com/t/font_2174310_7wjnvqcd6ds.js";
document.body.append(icon);

Vue.prototype.$api = api;

//全局注册icon-svg
Vue.component('icon-font', IconFont);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
